import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "picker"
    }}>{`Picker`}</h1>
    <p>{`Used to select one option from many.`}</p>
    <ContentBlock imgName="Form-picker.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
  struct Picker<Label, SelectionValue, Content> where Label : View, SelectionValue : Hashable, Content : View
*/
@State private var selectedIdx = 0
@State private var options = ["Option 1", "Option 2", "Option 3"]\n
var body: some View {
    NavigationView {
        Form {
            Section(header: Text("Using hard-coded .tag()")) {
                Picker(selection: $selectedIdx, label: Text("I'm a Picker in a Form"), content: {
                    Text("Option 1").tag(0)
                    Text("Option 2").tag(1)
                    Text("Option 3").tag(2)
                })
            }\n
            Section(header: Text("Using ForEach")) {
                Picker(selection: $selectedIdx, label: Text("I'm a Picker in a Form")) {
                    ForEach(0 ..< options.count) { index in
                        Text(self.options[index])
                    }
                    //  Can also do:
                    //  ForEach(0 ..< options.count) {
                    //      Text(self.options[$0])
                    //  }
                }
            }\n
            Section(header: Text("Segmented Picker Style (iOS 13)")) {
                Picker(selection: $selectedIdx, label: Text("I'm a Picker in a Form")) {
                    ForEach(0 ..< options.count) {
                        Text(self.options[$0])
                    }
                }.pickerStyle(SegmentedPickerStyle())
            }
        }
        .navigationBarTitle("NavigationView")
    }
}
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "inside-navigationview"
    }}>{`Inside `}<inlineCode parentName="h2">{`NavigationView`}</inlineCode></h2>
    <p>{`Wrap in a `}<inlineCode parentName="p">{`NavigationView`}</inlineCode>{` to open a new list view with all available options.`}</p>
    <ContentBlock imgName="Form-pickerOptions.png" mdxType="ContentBlock">
  <code className="clike">
    {`
NavigationView {
    Form {
        Section(header: Text("Using hard-coded .tag()")) {
            Picker(selection: $selectedIdx, label: Text("I'm a Picker in a Form"), content: {
                Text("Option 1").tag(0)
                Text("Option 2").tag(1)
                Text("Option 3").tag(2)
            })
        }
    }
    .navigationBarTitle("NavigationView")
}
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "pickerstyle"
    }}><inlineCode parentName="h2">{`.pickerStyle()`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Picker(selection: $selectedIdx, label: Text("I'm a Picker in a Form")) {
    ForEach(0 ..< options.count) {
        Text(self.options[$0])
    }
}
.pickerStyle(SegmentedPickerStyle())

Picker(selection: $selectedIdx, label: Text("I'm a Picker in a Form")) {
    ForEach(0 ..< options.count) {
        Text(self.options[$0])
    }
}
.pickerStyle(WheelPickerStyle())
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      